<template>
	<div class="clientsWithReviewContainer" v-if="windowWidth > 1200">
		<div class="clientsWithReviewTop">
			<div class="clientsWithReview__item animationBlockFadeUp" v-for="(item, index) in content.items" v-if="index < 8">
				<img :src="item.logo">
			</div>
		</div>
		<div class="clientsWithReviewBottom">
			<div class="clientsWithReviewBottom__left">
				<div class="clientsWithReview__item animationBlockFadeUp" v-for="(item, index) in content.items" v-if="index >= 8">
					<img :src="item.logo">
				</div>
			</div>
			<div class="clientsWithReviewBottom__right">
				<ReviewSlider :content="content.reviews" class="animationBlockFadeUp"></ReviewSlider>
			</div>
		</div>
	</div>
	<div class="clientsWithReviewContainer" v-else>
		<div class="clientsWithReviewItems">
			<div class="clientsWithReview__item animationBlockFadeUp" v-for="(item, index) in content.items">
				<img :src="item.logo">
			</div>
		</div>
		<ReviewSlider :content="content.reviews" class="animationBlockFadeUp clientsWithReview__reviews"></ReviewSlider>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/ClientsWithReview'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
			windowWidth: 1920
		}),
		mounted(){
			var comp = this;

			comp.windowWidth =  window.outerWidth;
			window.addEventListener('resize', function(){
				comp.windowWidth =  window.outerWidth;
			});
		},
		methods: {
		},
		components: {
			ReviewSlider: () => import('@/components/new/ReviewSlider'),
		}
	}
</script>